import React, { forwardRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useUserDetailsContext } from 'context/userDetailsContext';

const TextSnippetWrapper = forwardRef(({ type = 'text', onChange, ...rest }, ref) => {
  const { userDetails } = useUserDetailsContext();
  const snippets = userDetails?.text_snippets_map || {};

  const handleSnippetReplacement = (input) => {
    // Use regex to find words that match snippets in the text
    const regex = new RegExp(`\\b(${Object.keys(snippets).join('|')})\\b`, 'g');
    return input.replace(regex, (matched) => snippets[matched] || '');
  };

  const handleChange = (e) => {
    let inputValue = e.target.value;

    // Only process the input if the last character is a space or tab
    if (/\s/.test(inputValue.slice(-1))) {
      inputValue = handleSnippetReplacement(inputValue);
      e.target.value = inputValue; // Update the event's value directly
    }

    if (onChange) {
      onChange(e); // Pass the entire event object to the onChange prop
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault(); // Prevent the default tab behavior (focus shift)
      let inputValue = e.target.value;

      // Process the entire input string
      inputValue = handleSnippetReplacement(inputValue);
      e.target.value = inputValue; // Update the event's value directly

      if (onChange) {
        onChange(e); // Pass the entire event object to the onChange prop
      }
    }
  };

  return (
    <>
      {type === 'textarea' ? (
        <InputTextarea ref={ref} onChange={handleChange} onKeyDown={handleKeyDown} {...rest} />
      ) : (
        <InputText ref={ref} onChange={handleChange} onKeyDown={handleKeyDown} {...rest} />
      )}
    </>
  );
});

export default TextSnippetWrapper;
