import React, { useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useSecureMessageContext } from 'modules/messages/SecureMessages/Context/SecureMessageContext';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import { useWebSocketContext } from 'context/WebSocketConnectionContext';

function CaseName({
  showCaseSearch,
  messageDetails,
  redirectToPage,
  selectedMessage,
  setSelectedCase,
  setShowCaseSearch,
  setShowInviteUsers,
  setShowConfirmDialog,
  selectedCase,
  checkCaseRelatedUser,
  caseUserClients,
  loadCaseContacts,
  showSideBar,
  setShowSideBar,
  handleMarkAsUnRead,
}) {
  const { isLeadSpecific, isLeadClosed, isCaseClosed, isGlobalMessagesScreen, isFromClientPortal } = useSecureMessageContext();
  const { sideBarMessages } = useWebSocketContext();

  const [caseError, setCaseError] = useState('');
  const [newMsg, setNewMsg] = useState(false);

  useEffect(() => {
    if (sideBarMessages) {
      setNewMsg(true);
    }
  }, [sideBarMessages]);

  const handleSidebar = () => {
    setShowSideBar((preVal) => !preVal);
    setNewMsg(false);
  };

  useEffect(() => {
    if (messageDetails?.case_id) {
      let isForMessageLinkedCase = true;
      loadCaseContacts(messageDetails?.case_id, isForMessageLinkedCase);
    }
  }, [messageDetails]);

  return (
    <div className="row mb-4">
      <div className="col-sm-12">
        {!showCaseSearch ? (
          <>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <span className="text-bold me-2 text-nowrap">Case : </span>
                <span
                  onClick={(e) => {
                    let route = '/';
                    if (isLeadSpecific) {
                      messageDetails.case_id ? (route = `/leads/${messageDetails.case_id}`) : (route = '/leads');
                    } else {
                      messageDetails.case_id ? (route = `/cases/${messageDetails.case_id}`) : (route = '/cases');
                    }

                    redirectToPage(e, route);
                  }}
                  className="cursor-pointer text-primary-dark text-decoration-none case-details-link"
                >
                  {messageDetails?.case_name ? messageDetails.case_name : selectedMessage?.case_name ? selectedMessage.case_name : ''}
                </span>
                {isGlobalMessagesScreen && (
                  <>
                    <span
                      className={
                        'pointer px-2 border border-1 item-count-center ms-3 me-2' +
                        (isCaseClosed || (isLeadClosed && isLeadSpecific) ? 'pe-none opacity-50' : '')
                      }
                      onClick={() => {
                        setSelectedCase('');
                        setShowCaseSearch(true);
                        setShowInviteUsers(false);
                      }}
                    >
                      <i className="icon-edit fa-lg"></i>
                      <Tooltip content="Edit Case Name" position="top" target=".icon-edit" showDelay={500} />
                    </span>
                    {/* <span
                        className={
                          'cursor-pointer text-primary-dark text-decoration-none case-details-link pointer ' +
                          (isCaseClosed || (isLeadClosed && isLeadSpecific) ? 'pe-none opacity-50' : '')
                        }
                        onClick={() => {
                          setSelectedCase('');
                          setShowCaseSearch(true);
                          setShowInviteUsers(false);
                        }}
                      >
                        Edit Case
                      </span> */}
                  </>
                )}
              </div>
              <div>
                <Button
                  label={'Mark as Unread'}
                  className={
                    'btn-outline p-button-secondary outline py-1 px-3 me-2' +
                    (isCaseClosed || (isLeadClosed && isLeadSpecific) ? 'pe-none opacity-25' : '')
                  }
                  type="button"
                  style={{ overflow: 'inherit' }}
                  onClick={(e) => {
                    handleMarkAsUnRead(e, messageDetails, true);
                  }}
                  disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
                />
                <Button
                  label={showSideBar ? 'Close Sidebar' : 'Sidebar'}
                  className="btn-outline p-button-secondary outline py-1 px-3 me-2"
                  type="button"
                  style={{ overflow: 'inherit' }}
                  onClick={handleSidebar}
                >
                  {!showSideBar && newMsg && <span className="indicator-dot" />}
                </Button>
                <Button
                  label="Invite Users"
                  className="btn-outline p-button-primary p-button-secondary py-1 px-3 "
                  type="button"
                  onClick={() => {
                    setShowConfirmDialog(true);
                    setShowInviteUsers(true);
                    loadCaseContacts();
                  }}
                  disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <span className="fw-bold me-2">Case:</span>
              <CaseListDropDown
                isClient={isFromClientPortal}
                value={selectedCase}
                caseOnly
                onChange={(e) => {
                  setCaseError('');
                  if (selectedCase !== e) {
                    setSelectedCase(e);
                    if (e?.id) {
                      loadCaseContacts(e?.id);
                    }
                  }
                }}
                name="case"
                className="w-100 new-task-popup invite-user input-shadow"
                placeholder="Search Case"
              />
              <div className="d-flex mt-2 justify-content-end">
                <span
                  className="pointer px-2 border border-1 item-count-center mx-2"
                  onClick={() => {
                    setShowCaseSearch(false);
                    setSelectedCase('');
                    setCaseError('');
                  }}
                >
                  <i className="icon-Close fa-lg"></i>
                </span>
                <span
                  className="pointer px-2 border border-1 item-count-center"
                  onClick={() => {
                    if (selectedCase?.id) {
                      checkCaseRelatedUser(messageDetails?.message_to, caseUserClients);
                      setShowConfirmDialog(true);
                    } else {
                      setCaseError('Select Case');
                      return;
                    }
                  }}
                >
                  <i className="icon-done fa-lg"></i>
                </span>
              </div>
            </div>
            {caseError ? <span className="text-danger">{caseError}</span> : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default CaseName;
