import React from 'react';
import { Controller } from 'react-hook-form';

import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';

import { portalOptions } from 'modules/cases/CaseUtils/CaseUtils';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { getCaseNumberValidation } from 'services/lead/leadServices';
import { RequiredValidation } from 'components/UI/RequiredValidation/RequiredValidation';
import constants from 'constants/index';

const InformationDetails = (props) => {
  const { control, caseDetails, isCaseClosed, setValue, isLeadSpecific, caseStageList, errors } = props;
  const userDetails = useUserDetailsContext();

  const handlePortalChange = (e) => {
    if (e.value === 'enable') {
      setValue('case_details.is_create_client_portal', true);
    } else {
      setValue('case_details.is_create_client_portal', false);
    }
  };

  const validateCaseNumber = async (value) => {
    if (!Boolean(value)) {
      return true;
    }
    return new Promise(async (resolve) => {
      const response = await getCaseNumberValidation(value);
      if (response?.data?.length === 0 || response?.data?.[0]?.case_id === caseDetails?.case_id) {
        resolve(true);
      } else resolve('Duplicate Case Number');
    });
  };

  return (
    <div>
      <div className="caption-bold mb-3">Details</div>
      <Card className="mb-4 shadow-middle">
        <div className="d-flex align-items-center py-2 F-size14 flex-wrap">
          <div className="col-md-6 col-12 text-muted p-medium">Case Number</div>
          <div className="col-md-6 col-12 col-sm-7 d-flex align-items-center edit-field">
            <Controller
              name="case_description.case_number"
              control={control}
              rules={{ validate: { asyncValidation: validateCaseNumber } }}
              render={({ field }) => (
                <InputText
                  className="rounded flex-grow-1 w-75 input-shadow F-size14"
                  placeholder="Enter Case Number"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value.trimStart())}
                  disabled={isCaseClosed}
                />
              )}
            />
          </div>
          <RequiredValidation field="case_description.case_number" message="Case Number Already Exist" errors={errors} />
        </div>
        {caseDetails && caseDetails.client && caseDetails.client.client_id ? (
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-6 col-12 text-muted p-medium">Clients Portal</div>
            <div className="col-md-6 col-12 text-capitalize">
              <Controller
                name="is_create_client_portal"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    value={field.value ? 'enable' : 'disable'}
                    options={portalOptions}
                    onChange={(e) => handlePortalChange(e)}
                    placeholder="Select"
                    filter
                    disabled={isCaseClosed}
                  />
                )}
              />
            </div>
          </div>
        ) : null}

        <div className="d-flex align-items-center py-2 F-size14 flex-wrap">
          <div className="col-md-6 col-12 text-muted p-medium">Case Status</div>
          <div className="col-md-6 col-12 d-flex align-items-center text-capitalize edit-field">
            {<div className="w-100 h-100">{isCaseClosed ? 'Closed' : 'Open'}</div>}
          </div>
        </div>

        {isCaseClosed && !isLeadSpecific ? (
          <>
            <div className="d-flex align-items-center py-2 F-size14 flex-wrap">
              <div className="col-md-6 col-12 text-muted p-medium">Case Closure Reason</div>
              <div className="col-md-6 col-12 d-flex align-items-center text-capitalize edit-field">
                {<div className="w-100 h-100">{caseDetails?.closure_reason ? caseDetails?.closure_reason : '-'}</div>}
              </div>
            </div>

            <div className="d-flex align-items-center py-2 F-size14 flex-wrap">
              <div className="col-md-6 col-12 text-muted p-medium">Case Outcome</div>
              <div className="col-md-6 col-12 d-flex align-items-center text-capitalize edit-field">
                {<div className="w-100 h-100">{caseDetails?.outcome_reason ? caseDetails?.outcome_reason : '-'}</div>}
              </div>
            </div>
          </>
        ) : null}

        {isLeadSpecific && caseDetails?.is_open === false && (
          <div className="d-flex align-items-center py-2 F-size14 flex-wrap">
            <div className="col-md-6 col-12 text-muted p-medium">Lead Closure Reason</div>
            <div className="col-md-6 col-12 d-flex align-items-center text-capitalize edit-field">
              {<div className="w-100 h-100">{caseDetails?.lead_close_reason ? caseDetails?.lead_close_reason : '-'}</div>}
            </div>
          </div>
        )}

        {!isLeadSpecific && (
          <div className="d-flex align-items-center py-2 F-size14 flex-wrap ">
            <div className="col-md-6 col-12 text-muted p-medium">Case Stage</div>

            {userDetails?.getPermission('cases') === constants.permissions.WRITE && (
              <div className="col-md-6 col-12 d-flex align-items-center flex-direction-column text-capitalize edit-field">
                <div className="w-100">
                  <Controller
                    name="case_description.case_stage"
                    control={control}
                    defaultValue={'Open'}
                    render={({ field }) => (
                      <Dropdown
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        options={caseStageList}
                        className="flex-grow-1 w-100 input-shadow"
                        optionDisabled={(data) => data.label?.toLowerCase() === 'closed'}
                        disabled={isCaseClosed}
                        placeholder="Select Case Stage"
                        filter
                        filterPlaceholder="Search"
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="d-flex align-items-center py-2 F-size14 flex-wrap ">
          <div className="col-md-6 col-6 text-muted p-medium">Review Requested</div>
          <div className="col-md-6 col-6 d-flex align-items-center flex-direction-column text-capitalize edit-field">
            <div className="w-100">
              <Controller
                name="case_description.review_requested"
                control={control}
                render={({ field }) => (
                  <InputSwitch
                    name={field.name}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    className="input-swich"
                    disabled={isCaseClosed}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center py-2 F-size14 flex-wrap ">
          <div className="col-md-6 col-6 text-muted p-medium">Review Received</div>
          <div className="col-md-6 col-6 d-flex align-items-center flex-direction-column text-capitalize edit-field">
            <div className="w-100">
              <Controller
                name="case_description.review_received"
                control={control}
                render={({ field }) => (
                  <InputSwitch
                    name={field.name}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    className="input-swich"
                    disabled={isCaseClosed}
                  />
                )}
              />
            </div>
          </div>
        </div>
        {isLeadSpecific && (
          <>
            <div className="d-flex align-items-center py-2 F-size14 flex-wrap">
              <div className="col-md-6 col-6 text-muted p-medium">Lead Created</div>
              <div className="col-md-6 col-6 d-flex align-items-center flex-direction-column text-capitalize edit-field">
                <Calendar
                  monthNavigator
                  yearNavigator
                  yearRange="1990:2100"
                  showButtonBar
                  dateFormat={constants.date_format}
                  value={caseDetails?.lead_created_date ? new Date(caseDetails?.lead_created_date) : null}
                  className="rounded flex-grow-1 w-100 input-shadow"
                  disabled
                  placeholder="-"
                />
              </div>
            </div>
            <div className="d-flex align-items-center py-2 F-size14 flex-wrap">
              <div className="col-md-6 col-6 text-muted p-medium">Last Updated</div>
              <div className="col-md-6 col-6 d-flex align-items-center flex-direction-column text-capitalize edit-field">
                <Calendar
                  monthNavigator
                  yearNavigator
                  yearRange="1990:2100"
                  showButtonBar
                  dateFormat={constants.date_format}
                  value={caseDetails?.time ? new Date(caseDetails?.time) : null}
                  className="rounded flex-grow-1 w-100 input-shadow"
                  disabled
                  placeholder="-"
                />
              </div>
            </div>
            {caseDetails?.is_open === false && (
              <div className="d-flex align-items-center py-2 F-size14 flex-wrap">
                <div className="col-md-6 col-6 text-muted p-medium">Date Closed</div>
                <div className="col-md-6 col-6 d-flex align-items-center flex-direction-column text-capitalize edit-field">
                  <Calendar
                    monthNavigator
                    yearNavigator
                    yearRange="1990:2100"
                    showButtonBar
                    dateFormat={constants.date_format}
                    value={caseDetails?.case_closed_date ? new Date(caseDetails?.case_closed_date) : null}
                    className="rounded flex-grow-1 w-100 input-shadow"
                    disabled
                    placeholder="-"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default InformationDetails;
