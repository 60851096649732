/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { toastConstant } from 'constants/toastmessage';

import { sendInviteToUser } from 'services/messageServices';

import { viewCaseMessageDetails } from 'services/messages/messageService';

import { WebSocketConnectionContext } from 'context/WebSocketConnectionContext';
import { useToast } from 'context/ToastContext';

import { handleRedirection, openLinkInBlank } from 'utils/utils';

import InviteDialogConfirmation from './components/InviteDialogConfirmation/InviteDialogConfirmation';
import InviteDialog from './components/InviteDialog/InviteDialog';
import ThreadHeading from './components/ThreadHeading/ThreadHeading';
import ConversationBetween from './components/ConversationBetween/ConversationBetween';
import CaseName from './components/CaseName/CaseName';
import MessageContainer from './components/MessageContainer/MessageContainer';
import { useSecureMessageContext } from '../Context/SecureMessageContext';
import { getClientUserList } from 'services/users/userServices';
import { UserDetailsContext } from 'context/userDetailsContext';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';

const MessageDetails = () => {
  const history = useHistory();
  const { message_id } = useParams();

  const { addToast } = useToast();
  const { isFromClientPortal, handleMessageStatus, isLeadSpecific } = useSecureMessageContext();
  const websocketContext = useContext(WebSocketConnectionContext);

  const userContext = useContext(UserDetailsContext);

  const { isGlobalMessagesScreen } = useSecureMessageContext();

  const [messageDetails, setMessageDetails] = useState('');

  const [showCaseSearch, setShowCaseSearch] = useState(false);

  const [selectedCase, setSelectedCase] = useState('');
  const [caseUserClients, setCaseUserClients] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [showInviteUsers, setShowInviteUsers] = useState(false);
  const [usersNotInChangedCase, setUsersNotInChangedCase] = useState([]);
  const [showSideBar, setShowSideBar] = useState(false);

  const [selectedUser, setSelectedUser] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [inviteInitiated, setInviteInitiated] = useState(false);
  const [usersLinkedToCaseOfSelectedMessage, setUsersLinkedToCaseOfSelectedMessage] = useState([]);

  useEffect(() => {
    fetchCaseMessageDetails();
  }, []);

  useEffect(() => {
    if (websocketContext.response) {
      if (websocketContext.response.secure_message_type === 'reply' && websocketContext.response.body) {
        if (
          messageDetails &&
          messageDetails.message &&
          websocketContext.response.body.message_thread_id === messageDetails.message_thread_id
        ) {
          messageDetails.message.push(websocketContext.response.body);
          setMessageDetails({ ...messageDetails });
        }
        websocketContext.setResponse();
      }
    }
    if (websocketContext.secureMessageData) {
      if (websocketContext.secureMessageData.secure_message_type === 'reply' && websocketContext.secureMessageData.body) {
        if (
          messageDetails &&
          messageDetails.message &&
          websocketContext.secureMessageData.body.message_thread_id === messageDetails.message_thread_id
        ) {
          messageDetails.message.push(websocketContext.secureMessageData.body);
          setMessageDetails({ ...messageDetails });
        }
        websocketContext.setSecureMessageData();
      }
    }
  }, [websocketContext.response, websocketContext.secureMessageData]);

  useEffect(() => {
    if (websocketContext.secureMessageError) {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.MESSAGE_SEND_FAILURE);
      websocketContext.setSecureMessageError();
    }
  }, [websocketContext.secureMessageError]);

  //fetch case-specific contacts for selected message

  //open link in new tab
  const redirectToPage = (e, path) => {
    e.preventDefault();
    if (!openLinkInBlank(path)) {
      history?.push(path);
    }
  };

  const checkCaseRelatedUser = (selectedUsers, usersLinkedToCaseOfSelectedMessage) => {
    let filteredUsers = selectedUsers?.filter((one) => {
      let findeditems = !usersLinkedToCaseOfSelectedMessage?.find((two) => {
        let condition;
        if (one?.client_cognito_username) {
          condition = one?.client_cognito_username === two?.client_cognito_username;
        } else {
          condition = one?.contact_id === two?.contact_id;
        }

        return condition;
      });
      return findeditems;
    });

    let filter_groups = selectedUsers.filter((item) => item?.res_type === 'user-group');

    setUsersNotInChangedCase([...filteredUsers, ...filter_groups]);
  };

  const handleInvitingUser = () => {
    try {
      let groups = [];
      let users = [];
      selectedUser.forEach((item) => {
        if (item?.res_type === 'user-group') {
          groups.push({
            group_id: item.user_group_id,
            group_name: item.group_name,
            sk: item.sk,
          });
        } else {
          users.push(item);
        }
      });

      let data = {
        invited_user: users,
        invited_group: groups,
      };
      sendInviteToUser(messageDetails?.sk, messageDetails?.is_lead, data)
        .then((response) => {
          if (response.data) {
            fetchCaseMessageDetails();

            addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, response?.data?.body);
            setSelectedUser([]);
            setFilteredUserList([]);
            setInviteInitiated(false);
            setShowConfirmDialog(false);
            setCaseUserClients([]);
          }
        })
        .catch((err) => {
          console.log('invite-error', err);

          let errorMsg = err?.response?.data ?? toastConstant.message.CASE_UPDATED_FAILED;

          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, errorMsg);
          setSelectedCase('');
          setSelectedUser([]);
          setFilteredUserList([]);
          setShowConfirmDialog(false);
          setCaseUserClients([]);
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchCaseMessageDetails = () => {
    let obj = {};
    obj.id = message_id;
    obj.pagination_token = '';
    obj.pagination_direction = 'reverse';

    viewCaseMessageDetails(obj)
      .then((response) => {
        let res = response.data;
        // if (mssgData.res_type === 'message-reply') {
        //   res.message = [...response?.data?.message];
        // }
        setMessageDetails(res);
        setShowCaseSearch(false);
        setSelectedCase('');
      })
      .catch((error) => {
        console.log(error);
        setShowCaseSearch(false);
        setSelectedCase('');
      });
  };

  const loadCaseContacts = (caseId = '', isMessageLinkedCase = false) => {
    let obj = {
      caseId: caseId,
      all_contacts: isGlobalMessagesScreen ? true : false,
      contact_id: isGlobalMessagesScreen ? userContext?.userDetails?.contact_id : false,
      is_team_member_list: isGlobalMessagesScreen ? true : false,
      is_include_groups: true,
      active_groups: true,
    };
    getClientUserList(obj)
      .then((res) => {
        if (isMessageLinkedCase) {
          setUsersLinkedToCaseOfSelectedMessage(res.data);
        }
        setCaseUserClients(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMarkAsUnRead = (e, messageDetails, isRead) => {
    handleMessageStatus(e, messageDetails, isRead, message_id);
    let url = isFromClientPortal
      ? '/client/messages'
      : isGlobalMessagesScreen
      ? '/messages'
      : `/${isLeadSpecific ? 'leads' : 'cases'}/${messageDetails?.case_id}/messages`;
    handleRedirection(history, url);
  };

  return (
    <React.Fragment>
      <div className="px-2">
        <GlobalLoader />
        <ThreadHeading messageDetails={messageDetails} handleMarkAsUnRead={handleMarkAsUnRead} isFromClientPortal={isFromClientPortal} />

        {!window.location.href.includes('client') && (
          <ConversationBetween messageDetails={messageDetails} redirectToPage={redirectToPage} />
        )}

        {!Boolean(isFromClientPortal) && (
          <CaseName
            selectedMessage={history?.location?.state?.msg}
            {...{
              setShowSideBar,
              loadCaseContacts,
              setUsersLinkedToCaseOfSelectedMessage,
              caseUserClients,
              checkCaseRelatedUser,
              setShowInviteUsers,
              showCaseSearch,
              messageDetails,
              redirectToPage,
              selectedCase,
              setSelectedCase,
              setShowCaseSearch,
              setShowConfirmDialog,
              showSideBar,
              handleMarkAsUnRead,
            }}
          />
        )}

        <MessageContainer {...{ messageDetails, showSideBar, isGlobalMessagesScreen }} />
      </div>

      {Boolean(showConfirmDialog) && (
        <InviteDialog
          showInviteUsers={showInviteUsers}
          selectedUser={selectedUser}
          filteredUserList={filteredUserList}
          caseUserClients={caseUserClients}
          setFilteredUserList={setFilteredUserList}
          messageDetails={messageDetails}
          setSelectedUser={setSelectedUser}
          checkCaseRelatedUser={checkCaseRelatedUser}
          usersLinkedToCaseOfSelectedMessage={usersLinkedToCaseOfSelectedMessage}
          usersNotInChangedCase={usersNotInChangedCase}
          setUsersNotInChangedCase={setUsersNotInChangedCase}
          selectedCase={selectedCase}
          showConfirmDialog
          setShowConfirmDialog={setShowConfirmDialog}
          setShowInviteUsers={setShowInviteUsers}
          setInviteInitiated={setInviteInitiated}
          handleInvitingUser={handleInvitingUser}
          fetchCaseMessageDetails={fetchCaseMessageDetails}
          setSelectedCase={setSelectedCase}
        />
      )}

      <InviteDialogConfirmation
        inviteInitiated={inviteInitiated}
        setSelectedUser={setSelectedUser}
        setInviteInitiated={setInviteInitiated}
        handleInvitingUser={handleInvitingUser}
        redirectToPage={redirectToPage}
        messageDetails={messageDetails}
        usersNotInChangedCase={usersNotInChangedCase}
      />
    </React.Fragment>
  );
};

export default MessageDetails;
