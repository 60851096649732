import { useState, useEffect, useRef, useCallback } from 'react';
import { useWebSocketContext } from 'context/WebSocketConnectionContext';
import { getSidebarMessages } from 'services/messages/messageService';
import { getTenantDetails } from 'utils/utils';
import { useUserDetailsContext } from 'context/userDetailsContext';

export function useSidebar(messageDetails) {
  const { sideBarMessages, sendMessage } = useWebSocketContext();
  const { profileData } = useUserDetailsContext();
  const [tenantId] = getTenantDetails();

  const [messages, setMessages] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const listRef = useRef(null);

  const fetchSidebarMessages = () => {
    setLoading(true);
    getSidebarMessages(messageDetails?.sk, 10, nextToken)
      .then((res) => {
        const finalData = res?.data?.sidebar_message?.reverse();
        // check added to avoid duplicate data from websocket on initial load
        if (nextToken) {
          setMessages((preVal) => [...finalData, ...preVal]);
        } else {
          setMessages(finalData);
        }
        setNextToken(res?.data?.next_token);
        setTimeout(() => {
          if (listRef.current) {
            listRef.current.scrollToItem(finalData?.length - 1, 'smart'); // Scroll to the bottom when new messages are loaded
          }
        }, 100);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const fetchNextItems = () => {
    if (nextToken && !loading) {
      fetchSidebarMessages(); // fetch next items
    }
  };

  const handleSendMessage = useCallback(
    async (message) => {
      const msg = {
        data: {
          message: String(message).trim(),
          tenant_id: tenantId,
          name: {
            first_name: profileData?.first_name,
            last_name: profileData?.last_name,
            middle_name: profileData?.middle_name,
          },
        },
        type: 'sidebar_message',
        message_thread_id: messageDetails?.message_thread_id,
        case_id: messageDetails?.case_id,
        action: 'message',
        message_to: messageDetails?.message_to,
      };
      await sendMessage(msg);
    },
    [sendMessage, messageDetails, tenantId, profileData]
  );

  useEffect(() => {
    fetchSidebarMessages();
  }, []);

  useEffect(() => {
    if (sideBarMessages && sideBarMessages?.message_thread_id === messageDetails?.message_thread_id) {
      setMessages((preVal) => [...preVal, sideBarMessages]);
      setTimeout(() => {
        if (listRef.current) {
          listRef.current.scrollToItem(messages?.length, 'smart'); // Scroll to the bottom when new messages are loaded
        }
      }, 100);
    }
  }, [sideBarMessages]);

  return { messages, loading, listRef, handleSendMessage, fetchNextItems };
}
